<template>
  <div class="top-product">
    <div class="head">
      <p>Your Top Products</p>
      <artmisads-select-button @click="onOpen" :showNextIcon="true">See more</artmisads-select-button>
    </div>
    <div class="list">
      <div @click="() => onOpen(item)" class="item" :key="item.productId" v-for="item in props.topProductList">
        <img v-if="item.productImageUrl" :src="item.productImageUrl" />
        <span v-else></span>
        <div class="cont">
          <p>{{item.productTitle}}</p>
          <div class="num">
            <div>
              <span>Sales: </span>
              <span>{{item.salesAmount ? AMAZON_CURRENCY_MAPPER[props.marketplace] : ''}}{{item.salesAmount}}</span>
            </div>
            <div>
              <span>Commission: </span>
              <span>{{item.commissionAmount}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
  import { useRouter } from "vue-router";
  import { AMAZON_CURRENCY_MAPPER } from '~/contants/common';

  const props = defineProps(['topProductList', 'marketplace']);
  const router = useRouter();

  const onOpen = data => {
    const { href } = router.resolve({ path: '/publisher/reports/product' });
    if (data) {
      localStorage.setItem('artemisAds_dashboard_click_top_products', data.productAsin);
    }
    window.open(href, '_self');
  }

</script>
<style lang="less" scoped>
  .top-product {
    box-shadow: var(--section-box-shadow-5);
    background-color: var(--color-white);
    border-radius: 8px;
    padding: 16px 0 16px 8px;
    .head {
      display: flex;
      justify-content: space-between;
      padding: 0 16px 16px 0;
      border-bottom: 1px solid var(--border-4);
      margin-left: 8px;
      > p {
        height: 28px;
        color: var(--dark-1);
        font-weight: 600;
        font-size: 20px;
        letter-spacing: 0.5px;
        line-height: 28px;
      }
      button {
        svg {
          width: 14px;
          height: 14px;
          margin-left: 4px;
        }
      }
    }
    .list {
      display: flex;
      flex-direction: column;
      padding: 8px 8px 0 0;
      .item {
        display: flex;
        padding: 8px;
        cursor: pointer;
        transition: .4s ease;
        margin-top: 8px;
        border-radius: 8px;
        &:hover {
          background-color: var(--bg-gray-1);
        }
        > span {
          min-width: 56px;
          width: 56px;
          height: 56px;
          background: var(--bg-gray-2);
          border-radius: 8px;
        }
        > img {
          width: 56px;
          min-width: 56px;
          height: 56px;
          object-fit: contain;
        }
        .cont {
          width: 100%;
          margin-left: 12px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          > p {
            width: calc(100% - 68px);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            height: 22px;
            color: var(--dark-1);
            font-weight: 500;
            font-size: 14px;
            letter-spacing: 0.4px;
            line-height: 22px;
            text-decoration: underline;

          }
          .num {
            display: flex;
            margin-top: 8px;
            > div {
              display: flex;
              width: 120px;
              > span {
                display: flex;
                &:first-child {
                  color: var(--dark-3);
                  height: 16px;
                  font-weight: 400;
                  font-size: 12px;
                  letter-spacing: 0.3px;
                  line-height: 16px;
                }
                &:last-child {
                  height: 16px;
                  color: var(--dark-2);
                  font-weight: 600;
                  font-size: 12px;
                  letter-spacing: 0.3px;
                  line-height: 16px;
                  margin-left:4px;
                }
              }
            }
          }
        }
      }
    }
  }
</style>

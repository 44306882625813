
import dayjs from "dayjs";

export const RESPORTS_TAG_ARR = [
  { dataKey: 'totalClicks', label: 'Clicks', key: 'clicks', value: '1200', icon: 'jian-dianji', className: 'clicks-tag' },
  { dataKey: 'totalDetailPageViews', label: 'Detail Page Views', key: 'detailPageViews', value: '1200', icon: 'yanjing-kai', className: 'detailPageViews-tag' },
  { dataKey: 'totalAddToCart', label: 'Add to Cart', key: 'addToCart', value: '1200', icon: 'jiagouwuche', className: 'addToCart-tag' },
  { dataKey: 'totalConversions', label: 'Conversions', key: 'conversions', value: '1200', icon: 'zhuanhuan', className: 'conversions-tag' },
  { dataKey: 'totalConversionsRate', label: 'Conversions Rate', key: 'conversionsRate', value: '120', icon: 'tubiaoshangsheng', className: 'conversions-rate-tag', isPercent: true },
  { dataKey: 'totalSalesAmount', label: 'Sales', key: 'salesAmount', value: '1200', icon: 'yinhangka', className: 'sales-tag' },
  { dataKey: 'totalAcos', label: 'ACOS', key: 'acosAmount', value: '1200', icon: 'zhanbi', className: 'acos-tag', isPercent: true },
  { dataKey: 'totalDiscountedSalesAmount', label: 'Sales for Commission', key: 'discountedSalesAmount', value: '1200', icon: 'sale', className: 'sales-commission-tag' },
  { dataKey: 'totalCommissionAndFeeAmount', label: 'Commission', key: 'commissionAndFeeAmount', value: '200', icon: 'baifenhao', className: 'commssion-tag' },
  { dataKey: 'totalCommissionAmount', label: 'Commission', key: 'commissionAmount', value: '200', icon: 'baifenhao', className: 'commssion-tag' },
  { dataKey: 'totalBonusAmount', label: 'Bonus', key: 'bonusAmount', value: '120', icon: 'jine', className: 'bonus-tag' },
]

export const RESPORTS_TYPE_MAP = {
  clicks: {
    name: 'Clicks',
    lineColor: 'rgba(115, 115, 255, 1)',
    lineBgOffsetZero: 'rgba(115, 115, 255, 0)',
    lineBgOffsetTop: 'rgba(115, 115, 255, .1)',
  },
  discountedSalesAmount: {
    name: 'Sales for Commission',
    lineColor: 'rgba(247, 94, 120, 1)',
    lineBgOffsetZero: 'rgba(247, 94, 120, 0)',
    lineBgOffsetTop: 'rgba(247, 94, 120, .1)',
  },
  detailPageViews: {
    name: 'Detail Page Views',
    lineColor: 'rgba(44, 175, 255, 1)',
    lineBgOffsetZero: 'rgba(44, 175, 255, 0)',
    lineBgOffsetTop: 'rgba(44, 175, 255, .1)',
  },
  addToCart: {
    name: 'Add to Cart',
    lineColor: 'rgba(242, 111, 233, 1)',
    lineBgOffsetZero: 'rgba(242, 111, 233, 0)',
    lineBgOffsetTop: 'rgba(242, 111, 233, .1)',
  },
  conversions: {
    name: 'Conversions',
    lineColor: 'rgba(39, 198, 199, 1)',
    lineBgOffsetZero: 'rgba(39, 198, 199, 0)',
    lineBgOffsetTop: 'rgba(39, 198, 199, .1)',
  },
  conversionsRate: {
    name: 'Conversions Rate',
    lineColor: 'rgba(121, 142, 189, 1)',
    lineBgOffsetZero: 'rgba(121, 142, 189, 0)',
    lineBgOffsetTop: 'rgba(121, 142, 189, .1)',
  },
  acosAmount: {
    name: 'ACOS',
    lineColor: 'rgba(115, 115, 255, 1)',
    lineBgOffsetZero: 'rgba(115, 115, 255, 0)',
    lineBgOffsetTop: 'rgba(115, 115, 255, .1)',
  },
  salesAmount: {
    name: 'Sales',
    lineColor: 'rgba(250, 118, 70, 1)',
    lineBgOffsetZero: 'rgba(250, 118, 70, 0)',
    lineBgOffsetTop: 'rgba(250, 118, 70, .1)',
  },
  commissionAndFeeAmount: {
    name: 'Commission',
    lineColor: 'rgba(115, 115, 255, 1)',
    lineBgOffsetZero: 'rgba(115, 115, 255, 0)',
    lineBgOffsetTop: 'rgba(115, 115, 255, .1)',
  },
  commissionAmount: {
    name: 'Commission',
    lineColor: 'rgba(115, 115, 255, 1)',
    lineBgOffsetZero: 'rgba(115, 115, 255, 0)',
    lineBgOffsetTop: 'rgba(115, 115, 255, .1)',
  },
  bonusAmount: {
    name: 'Bonus',
    lineColor: 'rgba(245, 162, 29, 1)',
    lineBgOffsetZero: 'rgba(245, 162, 29, 0)',
    lineBgOffsetTop: 'rgba(245, 162, 29, .1)',
  },
  
  conversionsRate: {
    name: 'Conversions Rate',
    lineColor: 'rgba(121, 142, 189, 1)',
    lineBgOffsetZero: 'rgba(121, 142, 189, 0)',
    lineBgOffsetTop: 'rgba(121, 142, 189, .1)',
  },
  
}

export const RANGE_PRESETS = [
  {
    label: 'Last 7 Days',
    value: [dayjs().add(-7, 'd'), dayjs()],
  },
  {
    label: 'Last 14 Days',
    value: [dayjs().add(-14, 'd'), dayjs()],
  },
  {
    label: 'Last 30 Days',
    value: [dayjs().add(-30, 'd'), dayjs()],
  },
  {
    label: 'Last 90 Days',
    value: [dayjs().add(-90, 'd'), dayjs()],
  },
  {
    label: 'Last Year',
    value: [dayjs().add(-365, 'd'), dayjs()],
  },
];

export const RANGE_PRESETS_ADMIN_COMMISSIONS = [
  {
    label: 'Last 7 Days',
    value: [dayjs().add(-7, 'd'), dayjs()],
  },
  {
    label: 'Last 14 Days',
    value: [dayjs().add(-14, 'd'), dayjs()],
  },
  {
    label: 'Last 30 Days',
    value: [dayjs().add(-30, 'd'), dayjs()],
  },
  {
    label: 'Last 90 Days',
    value: [dayjs().add(-90, 'd'), dayjs()],
  },
  {
    label: 'Last 365 Days',
    value: [dayjs().add(-365, 'd'), dayjs()],
  },
];

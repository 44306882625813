<template>
  <div ref="newUserModalRef" class="new-user-modal">
    <artmisads-modal
      :open="true"
      :width="1044"
      :destroyOnClose="true"
      :getContainer="() => newUserModalRef"
      :footer="null"
    >
      <a-spin :spinning="spinning">
       <div class="new-user-cont">
          <div class="bg">
            <img class="bg1" alt="artemisAds" src="../../../../assets/images/new-user-modal/new_user_modal_bg1.png" />
            <img  class="bg2"  alt="artemisAds" src="../../../../assets/images/new-user-modal/new_user_modal_bg2.png" />
          </div>
          <div class="cont">
            <div class="head">
              <p class="title">👏 Welcome to ArtemisAds</p>
            </div>
            <div class="main-cont-wrap">
              <div class="main-cont">
                <p class="title">Thank you for choosing ArtemisAds! <br/>
                  You may now select products and begin your promotion!</p>
                <p class="text">Feel free to connect with your personal operations specialist - we're here to help you succeed!</p>
                <qrCode/>
                <div class="btn" :class="stepNum === 2 ? 'single-btn': ''">
                  <artmisads-button type="primary" @click="publisherOnboardingCompleted">I konw</artmisads-button>
                </div>
              </div>
            </div>
          </div>
       </div>
      </a-spin>
    </artmisads-modal>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { publisherDashboardApi } from "~/server";
import qrCode from './qrCode.vue';
import jwt from 'jsonwebtoken';
import { useUserInfoStore } from '~/storeData/index';

const props = defineProps(['setShowNewUserModal']);

const store = useUserInfoStore();
const spinning = ref(false);
const newUserModalRef = ref();

const publisherOnboardingCompleted = async () => {
  spinning.value = true;
  const res = await publisherDashboardApi.publisherOnboardingCompleted();
  const { data, success } = res;
  if (success) {
    const userToken = data.token;
    const userInfo = jwt.decode(userToken);
    store.setUserInfo(userInfo, userToken);
    props.setShowNewUserModal(false);
  }
  spinning.value = false;
}

</script>

<style lang="less" scoped>
.new-user-modal {
  :deep(.ant-modal) {
    .ant-modal-close {
      display: none;
    }
    .ant-modal-body {
      padding: 0;
    }
  }
  .new-user-cont {
    position: relative;
    // height: 678px;
    background-image: linear-gradient(170deg, #E2D9FC 0%, #FCF0FA 16%, #FBFAFF 41%, #FBFBFE 100%);
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.06), 0 16px 48px 0 rgba(0, 0, 0, 0.1), inset 2px 2px 0 0 rgba(255, 255, 255, 0.6);
    border-radius: 8px;
    .bg {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      display: flex;
      justify-content: space-between;
      z-index: 0;
      .bg1 {
        width: 324px;
        height: 247px;
      }
      .bg2 {
        width:  125px;
        height: 125px;
        margin-right: 49px;
        margin-top: 9px;
      }
    }
  }
  .cont {
    display: flex;
    flex-direction: column;
    padding: 40px 40px 13px 40px;
  }
  .head {
    .title {
      color: var(--dark-1);
      font-size: 32px;
      font-weight: 600;
      line-height: 40px;
      letter-spacing: 0.69px;
      text-align: left;
    }
    .text {
      margin-top: 5px;
      font-weight: 400;
      font-size: 16px;
      color: var(--dark-2);
      letter-spacing: 0.4px;
      line-height: 24px;
    }
  }
  .main-cont-wrap {
    display: flex;
    justify-content: center;
  }
  .main-cont {
    position: relative;
    z-index: 1;
    margin-top: 24px;
    width: 964px;
    height: 535px;
    background: rgba(255, 255, 255, 0.7);
    border: 2px solid #FFFFFF;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.04), 0 2px 16px 0 rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    padding-top: 40px;
    &::before {
      position: absolute;
      content: '';
      width: 150px;
      height: 40px;
      background-color: #fff;
      top: 0;
      right: 0;
    }
    .title {
      font-weight: 600;
      font-size: 24px;
      color: var(--dark-1);
      letter-spacing: 0.6px;
      text-align: center;
      line-height: 36px;
    }
    .text {
      font-weight: 400;
      font-size: 16px;
      color: var(--dark-3);
      letter-spacing: 0.4px;
      text-align: center;
      line-height: 24px;
    }
  }
  .btn {
    display: flex;
    padding: 40px 0;
    justify-content: center;
    button {
      width: 224px;
    }
  }
}
</style>

<template>
  <div class="dashboard">
    <breadcrumbs :breadcrumbsData="breadcrumbsData" #icon>
      <svg class="icon" aria-hidden="true" style="fill: #fff">
        <use xlink:href="#icon-home"></use>
      </svg>
    </breadcrumbs>
    <noticeTool />
    <a-spin :spinning="spinning">
      <div class="content-main" ref="mainRef">
        <div class="total-view">
          <div class="left">
            <generalView
              :dataStatistics="dataStatistics"
            />
          </div>
          <div class="right">
            <nextSteps :nextStepsData="nextStepsData" />
          </div>
        </div>
        <div class="chart-content">
          <div class="update-time">
            <p>
              <span v-if="tagNumParams.lastUpdateAt">
                Last Update at:
                {{
                  moment(tagNumParams.lastUpdateAt).format("YYYY-MM-DD hh:mm")
                }}
              </span>
            </p>
            <artmisads-select-button @click="onOpenReports" :showNextIcon="true"
              >See All Reporting</artmisads-select-button
            >
          </div>
          <div class="filter">
            <dateFilter
              :params="params"
              :getList="getChartList"
              :setParams="setParams"
              platform="seller"
            />
          </div>
          <div class="tags">
            <filterTag
              :noNeedList="['acosAmount', 'bonusAmount', 'commissionAndFeeAmount']"
              :setParams="setParams"
              :tagNumParams="tagNumParams"
              :marketplace="params.marketplace"
              :tagMomMapData="tagMomMapData"
            />
          </div>
          <p class="metric-name">
            {{ RESPORTS_TYPE_MAP[params.metricName].name }}
          </p>
          <div class="chart">
            <chart
              :dataList="chartList"
              :width="chartWidth"
              showYName="metricValue"
              showXName="reportDateTimestamp"
              :lineColor="chartParams.lineColor"
              :isPercent="params.metricName === 'conversionsRate'"
              :lineBgOffsetTop="chartParams.lineBgOffsetTop"
              :lineBgOffsetZero="chartParams.lineBgOffsetZero"
              chartType="line"
              :selectDaysNum="selectDaysNum"
              :showCurrent="false"
              :startTimestamp="params.startReportDateTimestamp"
              :endTimestamp="params.endReportDateTimestamp"
              :metricName="params.metricName"
            />
          </div>
          <p class="note">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-info"></use>
            </svg>
            NOTE: A link will only start reporting at 10 total clicks. Clicks
            may take up to 24 hours to appear, conversions may take up to 48
            hours to appear.
          </p>
        </div>
        <div class="product-publisher">
          <div class="product" v-if="topProductList.length">
            <productTemp
              :topProductList="topProductList"
              :marketplace="params.marketplace"
            />
          </div>
          <div class="publisher" v-if="topBrandList.length">
            <brandTemp
              :topBrandList="topBrandList"
              :marketplace="params.marketplace"
            />
          </div>
        </div>
      </div>
    </a-spin>
    <lineChartModal
      v-if="showlineChartModal"
      title="Brand Report"
      :itemInfo="itemInfo"
      :onCancleModal="onCancleModal"
      pageType="sellerBrand"
      :marketplace="params.marketplace"
      :noNeedList="['acosAmount', 'commissionAmount']"
    />
    <newUserModal
      v-if="showNewUserModal"
      :setShowNewUserModal="setShowNewUserModal"
    />
  </div>
</template>
<script setup>
import { computed, onMounted, ref, watch } from "vue";
import moment from "moment";
import { useRouter } from "vue-router";

import { CURRENCY_MAPPER, COUNTRY_SITE_CODE_MAP } from "~/contants/common.js";
import {
  publisherReportsApi,
  publisherDashboardApi,
  brandApi,
  productsApi,
  publisherApi
} from "~/server/index";
import { RESPORTS_TYPE_MAP, RESPORTS_TEST_DATA } from "~/contants/reports";
import breadcrumbs from "~/pages/seller/breadcrumbs.vue";
import filterTag from "~/components/reports/filterTag.vue";
import chart from "~/components/reports/chart.vue";
import productTemp from "./productTemp.vue";
import brandTemp from "./brandTemp.vue";
import generalView from "./generalView.vue";
import newUserModal from './newUserModal';
import nextSteps from "./nextSteps.vue";
import dateFilter from "./dateFilter.vue";
import { useUserInfoStore } from "~/storeData/index";

const breadcrumbsData = {
  title: "Dashboard",
  color: "#26AADC",
  des: "Welcome to ArtemisAds! This is your Homepage",
  hasTitleIcon:true,
  links: [
    {
      name: "Home",
      path: "/seller/dashboard",
    },
    {
      name: "Dashboard",
      path: "/seller/dashboard",
    },
  ],
};

const showNewUserModal = ref(false);
const router = useRouter();
const store = useUserInfoStore();
const nextStepsData = ref([]);
const dataStatistics = ref({
  totalActiveBrands: 0,
  totalActiveProducts: 0,
  totalBrands: 0,
  totalProducts: 0,
});
const tagMomMapData = ref({})
const topProductList = ref([]);
const topBrandList = ref([]);
const showlineChartModal = ref(false);
const chartWidth = ref(1092);
const spinning = ref(false);
const chartList = ref([]);
const params = ref({
  marketplace: "amazon.com",
  startReportDateTimestamp: moment().subtract(30, "day").valueOf(),
  endReportDateTimestamp: moment().valueOf(),
  brandId: null,
  productId: null,
  metricName: "clicks",
  asin: null,
});

const itemInfo = ref();
const mainRef = ref();

const topParams = ref({
  marketplace: '',
  page: 1,
  size: 200,
  sortDirection: "desc",
  sortField: "clicks",
});

const tagNumParams = ref({
  totalClicks: null,
  totalDetailPageViews: null,
  totalAddToCart: null,
  totalConversions: null,
  totalSalesAmount: null,
  totalDiscountedSalesAmount: null,
  totalBonusAmount: null,
  totalCommissionAndFeeAmount: null,
  lastUpdateAt: null,
});

const selectDaysNum = computed(() => {
  const { endReportDateTimestamp, startReportDateTimestamp } = params.value;
  const intervalDays = moment(endReportDateTimestamp).diff(
    moment(startReportDateTimestamp),
    "days"
  );
  return intervalDays;
});

const chartParams = computed(() => {
  let lineColor, lineBgOffsetTop, lineBgOffsetZero, chartParamsData, itemData;
  itemData = RESPORTS_TYPE_MAP[params.value.metricName];
  lineColor = itemData.lineColor;
  lineBgOffsetTop = itemData.lineBgOffsetTop;
  lineBgOffsetZero = itemData.lineBgOffsetZero;
  chartParamsData = { lineColor, lineBgOffsetTop, lineBgOffsetZero };
  return chartParamsData;
});

const setShowNewUserModal = status => {
  showNewUserModal.value = status;
}

const getChartList = async () => {
  spinning.value = true;
  const res = await publisherReportsApi.getPublisherCommissionReportsPerformanceDailyMetric(params.value);

  if (res.success) {
    chartList.value = params.value.metricName == 'conversionsRate' ? res.data.map(item => {
      return {
        ...item,
        metricValue: (item.metricValue * 100).toFixed(2).replace(/(\.\d*?[1-9])0+$|\.0+$/, '$1')
      }
    }) : res.data;
  }
  spinning.value = false;
};

const getChartWidth = () => {
  const $chart = document.querySelector(".chart");
  const widthNum = $chart.clientWidth - 20;
  chartWidth.value = widthNum;
};

const setParams = (obj) => {
  const newObj = { ...params.value, ...obj };
  params.value = newObj;
};

watch(params, async (newValue, oldValue) => {
  if (newValue.metricName !== oldValue.metricName) {
    getChartList();
  } else {
    getPublisherCommissionReportsAgg();
    getChartList();
  }
});

watch(() => params.value.marketplace, async (newValue, oldValue) => {
  getData();
});

const onCancleModal = () => {
  showlineChartModal.value = false;
  itemInfo.value = undefined;
};

const getData = () => {
  topParams.value.marketplace = params.value.marketplace;
  publisherReportsApi
    .getPublisherCommissionReportsProduct(topParams.value)
    .then((res) => {
      const { success, data } = res;
      if (success && data.data.length) {
        topProductList.value = data.data.splice(0, 6);
      }
    });
    publisherReportsApi
    .getPublisherCommissionReportsBrand(topParams.value)
    .then((res) => {
      const { success, data } = res;
      if (success && data.data.length) {
        topBrandList.value = data.data.splice(0, 6);
      }
    });
    publisherDashboardApi.getPublisherDashboardDataStatistics().then((res) => {
    const { success, data } = res;
    if (success) {
      dataStatistics.value = data;
    }
  });
  publisherDashboardApi.getPublisherDashboardNextSteps().then((res) => {
    const { success, data } = res;
    if (success) {
      nextStepsData.value = data;
    }
  });
};

const getPublisherCommissionReportsAgg = async () => {
  let newParams = { ...params.value }, startDate, endDate, intervalTime, vsValue = [];
  intervalTime = newParams.endReportDateTimestamp - newParams.startReportDateTimestamp;
  newParams.startReportDateTimestamp = newParams.startReportDateTimestamp - intervalTime;
  newParams.endReportDateTimestamp = newParams.endReportDateTimestamp - intervalTime;
  const resNew = await publisherReportsApi.getPublisherCommissionReportsAgg(params.value);
  const resOld = await publisherReportsApi.getPublisherCommissionReportsAgg(newParams);
  if (resNew.success) {
    const newData = resNew.data;
    tagNumParams.value = newData;
    if (resOld.success) {
      const oldData = resOld.data;
      let momMap = {};
      for (let key in resNew.data) {
        let itemData = {};
        let oldValue = oldData[key], newValue = newData[key];
        if (newValue === 0 && oldValue === 0) {
          if (oldValue === 0) {
            itemData.type = "zero";
            itemData.value = `MoM 0.00%`;
          }
        } else if (newValue === 0 && oldValue !== 0) {
          itemData.type = "down";
          itemData.value = `MoM -100.00%`;
        } else if (newValue !== 0 && oldValue === 0) {
          itemData.type = "up";
          itemData.value = `MoM +100.00%`;
        } else {
          let percent = ((newValue - oldValue) / oldValue) * 100;
          if (percent === 0) {
            itemData.type = "zero";
            itemData.value = `MoM 0.00%`;
          } else {
            percent = percent.toFixed(2);
            if (percent > 0) {
              itemData.type = "up";
              itemData.value = `MoM +${percent}%`;
            } else {
              itemData.type = "down";
              itemData.value = `MoM ${percent}%`;
            }
          }
        }
        momMap[key] = itemData;
      }
      tagMomMapData.value = momMap;
    }
  }
};

const checkNewUser = () => {
  // showNewUserModal.value = store.userInfo.onboardingCompleted !== 'N';
  showNewUserModal.value = store.userInfo.onboardingCompleted === 'N';
}

const onOpenReports = () => {
  const { href } = router.resolve({ path: '/publisher/reports/performance' });
  window.open(href, '_self');
}

onMounted(() => {
  getChartWidth();
  getPublisherCommissionReportsAgg();
  getChartList();
  window.addEventListener("resize", function () {
    getChartWidth();
  });
  getData();
  checkNewUser();
});
</script>
<style lang="less" scoped>
.dashboard {
  .content-main {
    border-radius: 12px;
    overflow: hidden;
    border-radius: 12px;
    .update-time {
      display: flex;
      justify-content: space-between;
      padding: 4px 4px 16px 4px;
      > p {
        font-size: 14px;
        color: var(--dark-2);
        line-height: 19px;
        align-self: center;
      }
      button {
        align-self: center;
      }
    }
    .total-view {
      width: 100%;
      display: flex;
      .left {
        width: 63%;
      }
      .right {
        width: 37%;
        margin-left: 16px;
      }
    }
  }
  .chart-content {
    padding: 12px;
    box-shadow: var(--section-box-shadow-5);
    background-color: var(--color-white);
    border-radius: 8px;
    margin-top: 16px;
    .note {
      display: flex;
      height: 32px;
      line-height: 32px;
      background-color: var(--bg-gray-6);
      border: 1px solid var(--border-4);
      color: var(--dark-3);
      font-weight: 400;
      font-size: 12px;
      letter-spacing: 0.3px;
      border-radius: 8px;
      padding: 0 8px;
      margin: 16px 4px 4px 4px;
      svg {
        width: 16px;
        height: 16px;
        fill: var(--dark-3);
        margin-right: 8px;
        align-self: center;
      }
    }
    .metric-name {
      display: flex;
      font-weight: 600;
      font-size: 20px;
      color: var(--dark-1);
      letter-spacing: 0.5px;
      line-height: 28px;
      margin: 40px 0 30px 4px;
    }
    .tags {
    }
    .chart {
      padding: 0 10px;
    }
  }
  .product-publisher {
    margin-top: 16px;
    display: flex;
    .product {
      width: 50%;
    }
    .publisher {
      width: 50%;
      margin-left: 16px;
    }
  }
}
</style>

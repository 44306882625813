<template>
  <div class="qr-code">
    <div class="cont">
      <div class="item">
        <div class="icon-cont">
          <div class="icon-wrap slack">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-slack"></use>
            </svg>
          </div>
          <span class="name">Slack</span>
        </div>
        <div class="code">
          <img alt="artemisAds" src="../../../../assets/images/new-user-modal/slack.png" />
          <span>Scan the QR code</span>
        </div>
      </div>
      <div class="item">
        <div class="icon-cont">
          <div class="icon-wrap whatsapp">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-whatsapp"></use>
            </svg>
          </div>
          <span class="name">WhatsAPP</span>
        </div>
        <div class="code">
          <img alt="artemisAds" src="../../../../assets/images/new-user-modal/whatsApp.png" />
          <span>Scan the QR code</span>
        </div>
      </div>
      <div class="item">
        <div class="icon-cont">
          <div class="icon-wrap wechat">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-wechat"></use>
            </svg>
          </div>
          <span class="name">Wechat</span>
        </div>
        <div class="code">
          <img alt="artemisAds" src="../../../../assets/images/new-user-modal/wechat.png" />
          <span>Scan the QR code</span>
        </div>
      </div>
      <div class="item">
        <div class="icon-cont">
          <div class="icon-wrap slack">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-lark"></use>
            </svg>
          </div>
          <span class="name">Lark</span>
        </div>
        <div class="code">
          <img alt="artemisAds" src="../../../../assets/images/new-user-modal/lark.png" />
          <span>Scan the QR code</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>

</script>

<style lang="less" scoped>
  .qr-code {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    .cont {
      width: 768px;
      height: 256px;
      background: var(  --bg-gray-8);
      border: 1px solid #FFFFFF;
      border-radius: 8px;
      align-self: center;
      padding: 30px 48px;
      display: flex;
      justify-content: space-between;
      .item {
        width: 132px;
        .icon-cont {
          display: flex;
          .icon-wrap {
            display: flex;
            width: 32px;
            height: 32px;
            border: 1px solid var(  --border-4);
            border-radius: 8px;
            justify-content: center;
            &.slack {
              background: #FFFFFF;
            }
            &.whatsapp {
              background-color: var( --green-10);
              svg {
                fill: #fff;
              }
            }
            &.wechat {
              background-image: linear-gradient(180deg, #02E46E 0%, #02C461 99%);
              svg {
                fill: #fff;
              }
            }
           
            svg {
              width: 24px;
              height: 24px;
              align-self: center;
            }
          }
          .name {
            font-weight: 600;
            font-size: 14px;
            color: var(--dark-2);
            letter-spacing: 0.4px;
            line-height: 20px;
            margin-left: 6px;
            align-self: center;
          }
        }
        .code {
          background: #FFFFFF;
          box-shadow: var(--section-box-shadow-2);
          border-radius: 8px;
          padding: 10px 10px 7px 10px;
          margin-top: 12px;
          display: flex;
          flex-direction: column;
          img {
            width: 112px;
            height: 112px;
          }
          span {
            font-weight: 400;
            font-size: 11px;
            color: var(--dark-3);
            letter-spacing: 0.28px;
            text-align: center;
            line-height: 16px;
            margin-top: 7px;
          }
        }
      }
    }
  }
</style>
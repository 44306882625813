<template>
  <div class="filter-tag" ref="filterTagRef">
    <div
      v-for="item in tagData"
      :key="item.key"
      class="filter-tag-item-wrap"
      @click="() => changeSelect(item.key)"
    >
      <div
        :class="[
          'filter-tag-item',
          item.className,
          (props.pageType == 'sellerProduct' && item.key === 'bonusAmount') ||
          (props.naList || []).indexOf(item.key) != -1
            ? ' disabled'
            : '',
          activeKey === item.key ? 'select' : '',
        ]"
      >
        <div class="filter-tag-item-cont">
          <span class="name">
            {{ item.label }}
            <a-popover
              v-if="item.key === 'discountedSalesAmount'"
              :getPopupContainer="() => filterTagRef"
            >
              <template #content>
                <p class="tooltip-text">
                  Sales for Commission: Depends on product deals setting on
                  ArtemisAds, this is finally used for calculating commissions.
                </p>
              </template>
              <svg class="wenhao-icon icon" aria-hidden="true">
                <use xlink:href="#icon-wenhao-tianchong"></use>
              </svg>
            </a-popover>
            <a-popover
              v-else-if="item.key === 'acosAmount'"
              :getPopupContainer="() => filterTagRef"
            >
              <template #content>
                <p class="tooltip-text">ACOS = Commission / Sales</p>
              </template>
              <svg class="wenhao-icon icon" aria-hidden="true">
                <use xlink:href="#icon-wenhao-tianchong"></use>
              </svg>
            </a-popover>
          </span>
          <span class="tag-icon">
            <Icon :name="item.icon" />
          </span>
        </div>
        <span class="tag-num" v-if="item.isPercent">
          {{
            props.tagNumParams[item.dataKey]
              ? (props.tagNumParams[item.dataKey] * 100)
                  .toFixed(2)
                  .replace(/(\.\d*?[1-9])0+$|\.0+$/, '$1') + '%'
              : '0'
          }}
        </span>
        <span
          class="tag-num"
          v-else-if="(props.naList || []).indexOf(item.key) != -1"
          >N/A</span
        >
        <span class="tag-num" v-else>
          {{ props.tagNumParams[item.dataKey] ? getCurrency(item.key) : ''
          }}{{ props.tagNumParams[item.dataKey] }}
        </span>
        <div
          class="mom"
          v-if="props.tagMomMapData && props.tagMomMapData[item.dataKey]"
        >
          {{ props.tagMomMapData[item.dataKey].value }}
          <svg
            v-if="props.tagMomMapData[item.dataKey].type === 'up'"
            class="wenhao-icon icon mom-up"
            aria-hidden="true"
          >
            <use xlink:href="#icon-jiantou-shang-tianchong"></use>
          </svg>
          <svg
            v-else-if="props.tagMomMapData[item.dataKey].type === 'down'"
            class="wenhao-icon icon mom-down"
            aria-hidden="true"
          >
            <use xlink:href="#icon-jiantou-xia-tianchong"></use>
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { AMAZON_CURRENCY_MAPPER } from '~/contants/common'
import { onBeforeMount, ref, watch } from 'vue'
import Icon from '../Icon.vue'
import { RESPORTS_TAG_ARR } from '~/contants/reports'

const filterTagRef = ref()
const props = defineProps([
  'naList',
  'pageType',
  'defaultMetricName',
  'noNeedList',
  'setParams',
  'tagNumParams',
  'marketplace',
  'tagMomMapData',
])
const tagData = ref([])
const activeKey = ref(
  props.defaultMetricName ||
    ((props.naList || []).indexOf('clicks') == -1
      ? 'clicks'
      : 'detailPageViews')
)

const getCurrency = (key) => {
  let data = ''
  if (
    key === 'commissionAndFeeAmount' ||
    key === 'bonusAmount' ||
    key === 'discountedSalesAmount' ||
    key === 'salesAmount' ||
    key === 'commissionAmount'
  ) {
    data = AMAZON_CURRENCY_MAPPER[props.marketplace]
  }
  return data
}

const changeSelect = (metricName) => {
  if (
    (metricName != 'bonusAmount' ||
      (metricName == 'bonusAmount' && props.pageType != 'sellerProduct')) &&
    (props.naList || []).indexOf(metricName) == -1
  ) {
    activeKey.value = metricName
    props.setParams({ metricName })
  }
}

onBeforeMount(() => {
  let needList = [...RESPORTS_TAG_ARR]
  if (props.noNeedList) {
    needList = needList.filter(
      (item) => !props.noNeedList.find((i) => i === item.key)
    )
  }
  tagData.value = needList
})

watch(() => props.defaultMetricName, (val) => {
  activeKey.value = val
})
</script>
<style lang="less" scoped>
.filter-tag {
  gap: 8px;
  display: grid;
  grid-template-rows: repeat(2, auto);
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  :deep(.ant-popover) {
    width: 400px;
    .tooltip-text {
      padding: 16px;
    }
  }
}
.filter-tag-item {
  position: relative;
  min-height: 61.5px;
  user-select: none;
  box-sizing: border-box;
  background-color: var(--bg-gray-8);
  border-radius: 8px;
  padding: 8px 7px 7px 12px;
  cursor: pointer;
  transition: all 0.3s;
  &.disabled {
    cursor: not-allowed;
    background-color: var(--bg-gray-5);
    &:hover {
      background-color: var(--bg-gray-5);
    }
  }
  &:hover {
    background-color: var(--bg-gray-5);
  }
  .wenhao-icon {
    width: 16px;
    height: 16px;
    fill: var(--bg-gray-7);
    &:focus {
      outline: none;
    }
  }
  &.select {
    .filter-tag-item-cont {
      .name {
        color: var(--color-white);
      }
    }
    .tag-num {
      color: var(--color-white);
    }
    .mom {
      color: var(--color-white);
      svg {
        fill: var(--color-white);
      }
    }
    .wenhao-icon {
      outline: none;
      fill: var(--color-white);
    }
  }
  &.clicks-tag,
  &.acos-tag {
    &.select {
      background-color: var(--tag-bg-1);
      .tag-icon {
        background-color: var(--tag-bg-1);
      }
    }
    .tag-icon {
      background-color: var(--tag-icon-bg-1);
    }
  }
  &.detailPageViews-tag {
    &.select {
      background-color: var(--tag-bg-2);
      .tag-icon {
        background-color: var(--tag-bg-2);
      }
    }
    .tag-icon {
      background-color: var(--tag-icon-bg-2);
    }
  }
  &.addToCart-tag {
    &.select {
      background-color: var(--tag-bg-3);
      .tag-icon {
        background-color: var(--tag-bg-3);
      }
    }
    .tag-icon {
      background-color: var(--tag-icon-bg-3);
    }
  }
  &.conversions-rate-tag {
    &.select {
      background-color: var(--tag-bg-9);
      .tag-icon {
        background-color: var(--tag-bg-9);
      }
    }
    .tag-icon {
      background-color: var(--tag-icon-bg-9);
    }
  }
  &.conversions-tag {
    &.select {
      background-color: var(--tag-bg-4);
      .tag-icon {
        background-color: var(--tag-bg-4);
      }
    }
    .tag-icon {
      background-color: var(--tag-icon-bg-4);
    }
  }
  &.conversions-rate-tag {
    &.select {
      background-color: var(--tag-bg-9);
      .tag-icon {
        background-color: var(--tag-bg-9);
      }
    }
    .tag-icon {
      background-color: var(--tag-icon-bg-9);
    }
  }
  &.sales-tag {
    &.select {
      background-color: var(--tag-bg-5);
      .tag-icon {
        background-color: var(--tag-bg-5);
      }
    }
    .tag-icon {
      background-color: var(--tag-icon-bg-5);
    }
  }
  &.commssion-tag {
    &.select {
      background-color: var(--tag-bg-6);
      .tag-icon {
        background-color: var(--tag-bg-6);
      }
    }
    .tag-icon {
      background-color: var(--tag-icon-bg-6);
    }
  }
  &.bonus-tag {
    &.select {
      background-color: var(--tag-bg-7);
      .tag-icon {
        background-color: var(--tag-bg-7);
      }
    }
    .tag-icon {
      background-color: var(--tag-icon-bg-7);
    }
  }
  &.sales-commission-tag {
    &.select {
      background-color: var(--tag-bg-8);
      .tag-icon {
        background-color: var(--tag-bg-8);
      }
    }
    .tag-icon {
      background-color: var(--tag-icon-bg-8);
    }
  }
  .filter-tag-item-cont {
    display: flex;
    justify-content: space-between;
    .name {
      color: var(--dark-2);
      font-weight: 500;
      font-size: 13px;
      line-height: 20px;
      letter-spacing: 0.3px;
    }
    .tag-icon {
      width: 22px;
      height: 22px;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      svg {
        align-self: center;
        width: 16px;
        height: 16px;
        fill: var(--color-white);
      }
    }
  }
  .tag-num {
    color: var(--dark-2);
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    margin-top: 4px;
  }
  .mom {
    display: flex;
    color: var(--dark-3);
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.3px;
    text-align: left;
    margin-top: 3px;
    svg {
      width: 14px;
      height: 14px;
      margin-left: 5px;
      align-self: center;
    }
    .mom-down {
      fill: var(--green-3);
    }
    .mom-up {
      fill: var(--red-1);
    }
  }
}
</style>
